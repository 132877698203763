
.color000 {
	color: #fff !important;
}

.backImg2 {
	box-shadow: none !important;
	position: relative;
}

.backImg {
	position: relative;
	padding: 0px 20px;
	background-color: #fff !important;
	box-shadow: none !important;
	background-image: url('../../assets/sixbg.png');
	background-size: 100% 100%;
	align-self: center;
	align-items: center;
}

.backImgNo1 {
	position: relative;
	padding: 0px 20px;
	background-color: #fff !important;
	box-shadow: none !important;
	background-image: url('../../assets/sixbgtop.png');
	background-size: 100% 100%;
	border-radius: 25px !important;
	align-self: center;
	align-items: center;
}

.none_Icon {
	.rel-node-shape-1 {
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: center;
	}
}
